import { createAction } from 'redux-actions';
import Attribution from '@wowmaking/web2app';

import { ADJUST_LINK, ADJUST_TOKEN, } from 'src/constants/general';

import Analytics from 'src/modules/analytics';
import { init as initRemoteConfig } from '../remote-config/actions';

import { SET_LOADED } from './types';

const setLoaded = createAction(SET_LOADED);

export const load = () => (dispatch) => {
  Attribution.init();
  Attribution.trackAdjustImpression(ADJUST_TOKEN);

  return Analytics.init()
    .then(() => dispatch(initRemoteConfig()))
    .then(() => dispatch(setLoaded()))
    .then(() => Analytics.trackEvent('page', 'loaded'));
};

export const downloadApp = () => {
  Analytics.trackEvent('install_app', 'click');

  let link = Attribution.createAdjustLink(ADJUST_LINK, ADJUST_TOKEN);

  window.location.href = link;
};
