import React from 'react';
import ICONS from 'src/constants/icons';
import classes from './style.module.scss';

const IconCircle = ICONS.ICONS_CIRCLE;

const Preloader = () => (
  <div className={classes.preloader}>
    <span className={classes.spinner}>
      <IconCircle width="50px" height="50px" />
    </span>
  </div>
);

export default Preloader;
