import { SET_LOADED, SET_PENDING } from './types';

export const initialState = {
  loaded: false,
  pending: false,
};

const store = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LOADED:
      return {
        ...state,
        loaded: true,
      };

    case SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    default:
      return state;
  }
};

export default store;
